import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=286f6080&scoped=true&"
import script from "./collect.vue?vue&type=script&lang=js&"
export * from "./collect.vue?vue&type=script&lang=js&"
import style0 from "./collect.vue?vue&type=style&index=0&id=286f6080&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286f6080",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VCard,VImg,VSnackbar})
