<template>
    <v-card>
        <v-img max-height="900"  aspect-ratio="1.778" :src="require('../assets/bg-pc.png')" @click="jobApply">
        </v-img>
<!--        <v-btn class="white&#45;&#45;text" color="yellow" block  large @click="jobApply">
            点击参与丰采计划
        </v-btn>-->

        <v-snackbar v-model="snackbar" centered>
            {{message}}
        </v-snackbar>
    </v-card>
</template>

<script>
    export default {
        name: "collect",
        data(){
            return{
                snackbar: false,
                message: null,
                jobList: [],
                screenHeight: 600
            }
        },
        methods: {
            getJobList(){
                this.$http.post('job/jobList').then(({data}) => {
                    this.jobList = data.jobList;
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网络请求失败！';
                })
            },
            jobApply(){
                this.$router.push({path: '/resume'});
            }
        },
        created() {
            this.getJobList();
        }
    }
</script>

<style scoped>
    .logoBtn{
        font: 12px/1 Tahoma,Helvetica,Arial,"\5b8b\4f53",sans-serif;
        font-weight: bolder;
    }
</style>
